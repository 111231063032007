import React, { useContext, useState } from "react";
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { color, formStyles, breakpoints } from '../../../utils/style';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { registerUrl } from '../Config.js';
import IcomoonIcon from '../../shared/IcomoonIcon';
import StoreContext from '../../shared/Context/StoreContext';
import PopupWindow from '../../shared/PopupWindow';
import LinePopupContent from '../../Content/Register/LinePopupContent';
import GetWindowWidth from '../../shared/GetWindowWidth';

const Wrapper = styled('div')`
  ${formStyles}
  position: relative;
  padding: 0 0 20px;
  margin-top: 10px;
  text-align: left;
  .loading {
    position:relative;
  }
  .loading::before {
    content: "";
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background: ${color.black};
    opacity:0.7;
    z-index: 1;
  }
  .two-fields {
    .fieldWrap {
      width: 48%;
      display: inline-block;
      vertical-align: top;
      &:first-of-type {
        margin-right: 4%;
      }
    }
  }
  .red-button {
    float: right;
  }
  .button {
    svg {
      margin-left: 5px;
    }
    &:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .two-fields {
      .fieldWrap {
        width: 100%;
        padding-bottom: 10px;
        &:first-of-type {
          margin-right: 0;
        }
      }
    }
  }
`;

const MsgWrap = styled('div')`
  span {
    background-color:${color.yellow};
    color: ${color.black};
    padding:5px 10px;
    text-align:center;
    border-radius: 5px;
    
    @media (max-width: ${breakpoints.sm}) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 20;
      padding: 20px;
      border-radius: 0;
    }

    &.errorMsg {
      display: block;
      margin-bottom: 30px;
    }
  }
`;

const initialValues = {
  username: "",
  email: "",
  phone: "",
  password: "",
  register_type: { real_money: false, free_speen: false }
};

// Checkbox input
const Checkbox = ({
                    field: { name, value, onChange, onBlur },
                    form: { errors, touched, setFieldValue },
                    id,
                    label,
                    ...props
                  }) => {
  return (
    <div className="minimal-custom-checkbox">
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const FormBody = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = (values, { resetForm, setSubmitting, isValid }) => {
    values.subdomain = process.env.SUBDOMAIN;
    //values.domain = window.location.origin; //with http://
    values.domain = window.location.origin;
    let body = JSON.stringify(values);
    fetch(registerUrl, {
      method: "post",
      body: body
    })
      .then(function (response) {
        return response.text();
      })
      .then(function (body) {
        let data = JSON.parse(body);
        if (data.result * 1 === 0 && data.message) {
          setErrorMessage(data.message);
          setSubmitting(false);
        } else {
          navigate('/register-success/');
        }
      })
      .catch(function (error) {
        console.log("Send Request failed", error);
      });
  };

  const windowWidth = GetWindowWidth();

  const hideNotificationOnMobile = () => {
    if(windowWidth <= 600) setErrorMessage('');
  };

  return (
    <Wrapper>
      <MsgWrap>
        { errorMessage && <span className="errorMsg" onClick={ hideNotificationOnMobile }>{ errorMessage }</span> }
      </MsgWrap>
      <PopupWindow popupId="register-line-popup">
        <LinePopupContent/>
      </PopupWindow>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={values => {
          let errors = {};
          if (!(values.register_type.real_money || values.register_type.free_speen)) {
            errors.register_type = 'Memerlukan sekurang-kurangnya satu barang/benda';
          }
          return errors;
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().required('Maklumat ini diperlukan'),
          email: Yup.string().email('Email tidak sah').required('Maklumat ini diperlukan'),
          password: Yup.string().required('Maklumat ini diperlukan'),
          phone: Yup.string().required('Maklumat ini diperlukan'),
        })}
        render={({ errors, status, touched, isSubmitting, isValid, values }) => (
          <Form id="regStaticForm">
            <div className="fieldWrap iconField">
              <IcomoonIcon icon="user" color={color.white} size={18}/>
              {errors.username && touched.username && <div className="error-new">! {errors.username}</div>}
              <Field type="text" name="username" placeholder="Nama Pengguna" id="register-username-static"
                     value={values.username || ''}/>

            </div>
            <div className="fieldWrap iconField">
              <IcomoonIcon icon="pass" color={color.white} size={20}/>
              {errors.password && touched.password && <div className="error-new">! {errors.password}</div>}
              <Field type="password" name="password" placeholder="Kata Laluan" id="register-password-static"
                     value={values.password || ''}/>
            </div>
            <div className="two-fields">
              <div className="fieldWrap iconField">
                <IcomoonIcon icon="mobile" color={color.white} size={18}/>
                {errors.phone && errors.phone &&
                touched.phone && <div className="error-new">! {errors.phone}</div>}
                <Field type="text" name="phone" placeholder="Telefon" value={values.phone || ''}/>
              </div>
              <div className="fieldWrap iconField">
                <IcomoonIcon icon="mail" color={color.white} size={18}/>
                {errors.email && touched.email && <div className="error-new">! {errors.email}</div>}
                <Field type="email" name="email" placeholder="Email" value={values.email || ''}/>
              </div>
            </div>
            <div className="checkboxGroupWrap two-fields">
              <p>Sila pilih jenis pendaftaran:</p>

              <div className="fieldWrap">
                <Field
                  component={Checkbox}
                  name="register_type[free_speen]"
                  id="register-type-free-speen-static"
                  label="Mohon untuk bermain pusingan percuma"
                  value={values.register_type && values.register_type.free_speen ? values.register_type.free_speen : false}/>
              </div>

              <div className="fieldWrap">
                <Field component={Checkbox} name="register_type[real_money]" id="register-type-real-money-static"
                       label="Mohon untuk akaun kasino"
                       value={values.register_type && values.register_type.real_money ? values.register_type.real_money : false}/>
              </div>

              {Object.keys(errors).length === 1 && errors.register_type &&
              <div className="error">! {errors.register_type}</div>}
            </div>

            {status && status.msg && <div>{status.msg}</div>}
            <button aria-label="Hantar" className="button green-button" type="submit"
                    disabled={(isSubmitting || !isValid)}>
              <span>Hantar</span>
              <IcomoonIcon icon="arrow-right" color={color.white} size={16}/>
            </button>

          </Form>
        )}
      />
    </Wrapper>
  );
};


const RegistrationFormStatic = ({ games }) => {
  const value = useContext(StoreContext);
  const username = value.username;
  return (
    !username ? <React.Fragment>
      <FormBody games={games}/>
    </React.Fragment> : ''
  );
};

export default RegistrationFormStatic;